import {
  Avatar,
  Box,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Skeleton,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { FC, FormEventHandler, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiMoon, BiSun } from "react-icons/bi";
import { FiChevronDown, FiSearch } from "react-icons/fi";
import { GiHamburgerMenu } from "react-icons/gi";
import { RiNotification3Line } from "react-icons/ri";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import Input from "../../../../components/Input/Input";
import Logo from "../../../../components/Logo/Logo";
import AuthContext from "../../../../context/AuthContext";
import { BlurContext } from "../../../../context/BlurContext";
import { SidebarContext } from "../../../../context/SidebarContext";

const Navbar: FC = () => {
  const { t } = useTranslation();
  const { user, logout, isLoading: isAuthLoading } = useContext(AuthContext);
  const { isBlur } = useContext(BlurContext);
  const { setIsExpanded, isExpanded } = useContext(SidebarContext);
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const colorText = useColorModeValue("blackAlpha.700", "white");
  const [keyword, setKeyword] = useState(query.get("search")?.toString() || "");
  const [search] = useDebounce(keyword, 500);
  const { pathname } = useLocation();

  const handleSearchSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    navigate(`/menu?search=${keyword}`);
  };

  const handleLogout = () => {
    logout();
  };

  useEffect(() => {
    if (pathname === "/menu" && (search || query.get("search"))) {
      navigate(search ? `/menu?search=${search}` : "/menu");
    }
  }, [search, navigate, pathname, query]);

  const bg = useColorModeValue("white", "gray.800");
  const textPlaceholder = useColorModeValue("#94a3b8", "#fff");
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Flex
      as="nav"
      position="fixed"
      left={isExpanded ? ["0", "265px", "280px"] : "0"}
      right={"0"}
      top={0}
      padding={[3, 4]}
      alignItems={"center"}
      justifyContent={"space-between"}
      background={bg}
      filter={isBlur ? "blur(3px)" : ""}
      zIndex={99}
      boxShadow="sm"
    >
      <Flex alignItems={"center"} gap={"24px"}>
        {!isExpanded && (
          <Flex gap={"12px"}>
            <button onClick={() => setIsExpanded(true)}>
              <Icon as={GiHamburgerMenu} w={5} h={5} />
            </button>
            <Logo />
          </Flex>
        )}

        <Box display={["none", "block", "block"]}>
          <form onSubmit={handleSearchSubmit}>
            <Input
              placeholder={t("Search Product")}
              height={["28px", "32px", "48px"]}
              style={{ color: colorText }}
              _placeholder={{ color: textPlaceholder }}
              width={["100%", "213px", "573px"]}
              accessoryLeft={<Icon as={FiSearch} />}
              value={keyword}
              onChange={(e) => setKeyword(e.currentTarget.value)}
            />
          </form>
        </Box>
      </Flex>

      <Skeleton isLoaded={!isAuthLoading}>
        <Flex alignItems={"center"} gap={["17px", "17px", "24px"]}>
          <Flex gap={[3, 4]} alignItems="center">
            <button onClick={toggleColorMode}>
              <Icon
                as={colorMode == "dark" ? BiSun : BiMoon}
                color="gray.600"
                w={6}
                h={6}
              />
            </button>

            <button>
              <Icon as={RiNotification3Line} color="gray.600" w={6} h={6} />
            </button>

            <Menu>
              <MenuButton>
                <Flex alignItems={"center"} gap={1}>
                  <Avatar
                    src={user?.avatarSrc?.xs}
                    size="md"
                    name={user?.name}
                  />

                  <Icon as={FiChevronDown} color="gray.600" w={7} h={7} />
                </Flex>
              </MenuButton>

              <MenuList>
                <MenuItem onClick={() => navigate("settings")}>
                  {t("Profile")}
                </MenuItem>
                <MenuDivider />
                <MenuItem onClick={handleLogout}>{t("Logout")}</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
      </Skeleton>
    </Flex>
  );
};

export default Navbar;

import { FC, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { renderSuspense } from "../../helpers/renderSuspense";

const StoreCategories = lazy(() => import("./categories/StoreCategories"));
const StoreCategoryAdd = lazy(
  () => import("./categories/add/StoreCategoryAdd")
);
const StoreProducts = lazy(() => import("./products/StoreProducts"));
const StoreProductsAdd = lazy(() => import("./products/add/StoreProductsAdd"));

export const StoreRoutes: FC = () => {
  return (
    <Routes>
      <Route path="/categories" element={renderSuspense(<StoreCategories />)} />
      <Route
        path="/categories/add"
        element={renderSuspense(<StoreCategoryAdd />)}
      />
      <Route
        path="/categories/:categoryId/edit"
        element={renderSuspense(<StoreCategoryAdd />)}
      />
      <Route path="/products" element={renderSuspense(<StoreProducts />)} />
      <Route
        path="/products/add"
        element={renderSuspense(<StoreProductsAdd />)}
      />
      <Route
        path="/products/:productId/edit"
        element={renderSuspense(<StoreProductsAdd />)}
      />
    </Routes>
  );
};

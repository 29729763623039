import { FC, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { renderSuspense } from "../../helpers/renderSuspense";

const LeaderForum = lazy(() => import("./LeaderForum"));
const LeaderForumDetail = lazy(() => import("./detail/LeaderForumDetail"));
// const LeaderForumAdd = lazy(() => import("./"));

export const LeaderForumRoutes: FC = () => {
  return (
    <Routes>
      <Route path="/" element={renderSuspense(<LeaderForum />)} />
      <Route
        path="/:leaderForumId"
        element={renderSuspense(<LeaderForumDetail />)}
      />
      {/* <Route path="/suplies/add" element={renderSuspense(<LeaderForumAdd />)} />
      <Route
        path="/suplies/:suplyId/edit"
        element={renderSuspense(<LeaderForumAdd />)}
      /> */}
    </Routes>
  );
};

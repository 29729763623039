import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Pagination } from "../def/pagination.type";

type HookType = () => {
  pagination: Pagination;
  onServerPaginationChange: (serverData?: any) => void;
  reflectPaginationToUrl: (pagination: Pagination) => void;
};

const usePagination: HookType = () => {
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    limit: 10,
    total: 0,
  });

  const [query, setQuery] = useSearchParams();

  // on query string changed
  useEffect(() => {
    setPagination((_values) => ({
      ..._values,
      page: parseInt(query.get("page") || "1"),
      limit: parseInt(query.get("limit") || "10"),
    }));
  }, [query]);

  const onServerPaginationChange = useCallback(
    (serverData: any) => {
      if (serverData) {
        setPagination({
          ...pagination,
          total: serverData.pagination?.totalRows,
        });
      }
    },
    [pagination]
  );

  const reflectPaginationToUrl = useCallback(
    (_pagination: Pagination) => {
      const newQuery = new URLSearchParams();

      query.forEach((value, key) => {
        console.log(value, key);
        if (value) {
          newQuery.set(key, value);
        }
      });

      newQuery.set("page", _pagination.page.toString());
      newQuery.set("limit", _pagination.limit.toString());
      setQuery(newQuery);
    },
    [query, setQuery]
  );

  return {
    pagination,
    onServerPaginationChange,
    reflectPaginationToUrl,
  };
};

export default usePagination;

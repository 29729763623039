import { lazy, useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { BlurProvider } from "../context/BlurContext";
import { SidebarProvider } from "../context/SidebarContext";
import { renderSuspense } from "../helpers/renderSuspense";

import AdminLayout from "../layouts/Admin/AdminLayout";
import AuthLayout from "../layouts/Common/AuthLayout";
import { BlogRoutes } from "../pages/blog/blog.routes";
import { BusinessRoutes } from "../pages/business/business.routes";
import { ConsultationRoutes } from "../pages/consultation/consultation.routes";
import { ContentRoutes } from "../pages/content/content.routes";
import { DonationRoutes } from "../pages/donation/donation.routes";
import { EventRoutes } from "../pages/events/events.routes";
import { IibfToolsRoutes } from "../pages/iibf/iibfTool.routes";
import { LeaderForumRoutes } from "../pages/leaderForum/leaderForum.routes";
import { NotificationRoutes } from "../pages/notification/notification.routes";
import { SettingsRoutes } from "../pages/settings/settings.routes";
import { StoreRoutes } from "../pages/store/store.routes";
import { PaymentRoutes } from "../pages/subscription/payment/payment.routes";
import { UserRoutes } from "../pages/users/user.routes";

const Login = lazy(() => import("../pages/auth/Login"));
const Home = lazy(() => import("../pages/home/Home"));

function AdminApp() {
  const [isLoggedIn] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  // should replaced using component to Protect routes
  useEffect(() => {
    if (location.pathname === "/" && isLoggedIn) {
      navigate("/auth/login");
    }
  }, [isLoggedIn, location.pathname, navigate]);

  return (
    <BlurProvider>
      <SidebarProvider>
        <Routes>
          <Route path="/" element={<AuthLayout />}>
            <Route path="/auth/login" element={renderSuspense(<Login />)} />
          </Route>
          <Route path="/" element={<AdminLayout />}>
            <Route index element={renderSuspense(<Home />)} />

            <Route path="users/*" element={<UserRoutes />} />
            <Route path="consultation/*" element={<ConsultationRoutes />} />
            <Route path="content/*" element={<ContentRoutes />} />
            <Route
              path="subscription/payment-methods/*"
              element={<PaymentRoutes />}
            />
            <Route path="blog/*" element={<BlogRoutes />} />
            <Route path="business/*" element={<BusinessRoutes />} />
            <Route path="store/*" element={<StoreRoutes />} />
            <Route path="donation/*" element={<DonationRoutes />} />
            <Route path="notifications/*" element={<NotificationRoutes />} />
            <Route path="settings/*" element={<SettingsRoutes />} />
            <Route path="iibf-tools/*" element={<IibfToolsRoutes />} />
            <Route path="events/*" element={<EventRoutes />} />
            <Route path="leader-forum/*" element={<LeaderForumRoutes />} />
          </Route>
        </Routes>
      </SidebarProvider>
    </BlurProvider>
  );
}

export default AdminApp;

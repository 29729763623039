import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { ROLES } from "../def/user.def";

export const useRoles = () => {
  const { user } = useContext(AuthContext);

  const isForRoles = (roles?: ROLES[]) => {
    const isSuperAdmin = user?.roles.find(
      (_role) => _role.name === ROLES.SUPER_ADMIN
    );

    // default for super admin
    if (isSuperAdmin) {
      return true;
    }

    let isAllowed = false;

    user?.roles.forEach((_role) => {
      isAllowed = !!(isAllowed || roles?.includes(_role.name as ROLES));
    });

    return isAllowed;
  };

  return {
    isForRoles,
  };
};

import { Flex } from "@chakra-ui/layout";
import { Img } from "@chakra-ui/react";
import { FC } from "react";

type Props = {
  variant?: "white" | "color";
};

const Logo: FC<Props> = () => {
  return (
    <Flex textAlign={"center"} gap={2} alignItems="center">
      <Img src={require("./klik_bisnis.png")} h={[8, 10]} />
    </Flex>
  );
};

Logo.defaultProps = {
  variant: "color",
};

export default Logo;

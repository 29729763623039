import { FC, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { renderSuspense } from "../../helpers/renderSuspense";

const IibfTools = lazy(() => import("./IibfTools"));
const IibfToolsAdd = lazy(() => import("./add/IibfToolAdd"));
const IibfToolsDetail = lazy(() => import("./detail/IibfToolDetail"));

export const IibfToolsRoutes: FC = () => {
  return (
    <Routes>
      <Route path="/" element={renderSuspense(<IibfTools />)} />
      <Route path="/add" element={renderSuspense(<IibfToolsAdd />)} />
      <Route path="/:iibfId/edit" element={renderSuspense(<IibfToolsAdd />)} />
      <Route path="/:iibfId" element={renderSuspense(<IibfToolsDetail />)} />
    </Routes>
  );
};

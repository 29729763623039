import { FC, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { renderSuspense } from "../../helpers/renderSuspense";

const Donation = lazy(() => import("./Donation"));
const DonationDetail = lazy(() => import("./detail/DonationDetail"));

export const DonationRoutes: FC = () => {
  return (
    <Routes>
      <Route path="/" element={renderSuspense(<Donation />)} />
      <Route path="/:donationId" element={renderSuspense(<DonationDetail />)} />
    </Routes>
  );
};

import { switchAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

const primary = definePartsStyle({
  thumb: {
    bg: "#ffff",
  },
  track: {
    bg: "gray.400",
    _checked: {
      bg: "#3182ce",
    },
  },
});

export const switchTheme = defineMultiStyleConfig({
  variants: { primary },
});

import { FC, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { renderSuspense } from "../../helpers/renderSuspense";

const ContentPages = lazy(() => import("./pages/ContentPages"));
const ContentPageAdd = lazy(() => import("./pages/add/ContentPageAdd"));
const ContentSliders = lazy(() => import("./sliders/ContentSliders"));
const ContentSliderAdd = lazy(() => import("./sliders/add/ContentSliderAdd"));
// const SellsDetail = lazy(() => import("./sells/detail/SellsDetail"));

export const ContentRoutes: FC = () => {
  return (
    <Routes>
      <Route path="/pages" element={renderSuspense(<ContentPages />)} />
      <Route path="/pages/add" element={renderSuspense(<ContentPageAdd />)} />
      <Route
        path="/pages/:pageId/edit"
        element={renderSuspense(<ContentPageAdd />)}
      />
      {/* <Route path="/pages/:sellId" element={renderSuspense(<SellsDetail />)} /> */}
      <Route path="/sliders" element={renderSuspense(<ContentSliders />)} />
      <Route
        path="/sliders/add"
        element={renderSuspense(<ContentSliderAdd />)}
      />
      <Route
        path="/sliders/:sliderId/edit"
        element={renderSuspense(<ContentSliderAdd />)}
      />
    </Routes>
  );
};

import { FC, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { renderSuspense } from "../../helpers/renderSuspense";
import DeletedUser from "./deletedUser";

const Users = lazy(() => import("./Users"));
const UserAdd = lazy(() => import("./add/UserAdd"));
const UserDetail = lazy(() => import("./detail/UserDetail"));

export const UserRoutes: FC = () => {
  return (
    <Routes>
      <Route path="/" element={renderSuspense(<Users />)} />
      <Route path="/add" element={renderSuspense(<UserAdd />)} />
      <Route path="/delete-user" element={renderSuspense(<DeletedUser />)} />
      <Route path="/:userId/edit" element={renderSuspense(<UserAdd />)} />
      <Route path="/:userId" element={renderSuspense(<UserDetail />)} />
    </Routes>
  );
};

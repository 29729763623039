import { createContext, Dispatch, FC, SetStateAction, useState } from "react";

type ContextType = {
  isBlur: boolean;
  setIsBlur: Dispatch<SetStateAction<boolean>> | ((value: boolean) => null);
};

const defaultValue: ContextType = {
  isBlur: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setIsBlur: (value: boolean) => null,
};

export const BlurContext = createContext<ContextType>(defaultValue);

export const BlurProvider: FC = ({ children }) => {
  const [isBlur, setIsBlur] = useState<boolean>(false);

  return (
    <BlurContext.Provider value={{ isBlur, setIsBlur }}>
      {children}
    </BlurContext.Provider>
  );
};

import { Container } from "@chakra-ui/react";
import { FC, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { ROLES } from "../def/user.def";

const GuestPage: FC = ({ children }) => {
  const { user, isLoading } = useContext(AuthContext);
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (user) {
      const isMentor = user.roles.find((_role) => _role.name === ROLES.MENTOR);
      const fallbackRoute = isMentor ? "/consultations" : "/";

      navigate(params.get("from") || fallbackRoute);
    }
  }, [user, navigate, params]);

  if (isLoading) {
    return (
      <Container marginTop={[8, 12, 14]} centerContent>
        <p>{t("Loading")}...</p>
      </Container>
    );
  }

  return <>{children}</>;
};

export default GuestPage;

import "./Table.css";

import {
  Box,
  Button,
  Flex,
  Icon,
  Skeleton,
  Table as TableChakra,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import Pagination from "../../components/Pagination/Pagination";
import {
  HeaderGroup,
  TableOptions,
  usePagination,
  useTable,
} from "react-table";
import { useTranslation } from "react-i18next";
import { Pagination as PaginationType } from "../../def/pagination.type";
import { OrderType } from "../../def/order.type";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import classNames from "classnames";
import { useSearchParams } from "react-router-dom";

type Props<T extends object> = TableOptions<T> & {
  onChange?: (pagination: PaginationType) => void;
  onOrderChange?: (order: OrderType) => void;
  isLoading?: boolean;
  withImageSkeleton?: boolean;
  pagination?: PaginationType;
  order?: OrderType;
};

const Table: <T extends object>(props: Props<T>) => JSX.Element = (props) => {
  const { t } = useTranslation();
  const table = useTable(props, usePagination);
  const [query, setQuery] = useSearchParams();

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    table;

  const handleColumnClick = (column: HeaderGroup<any>) => {
    if (props.onOrderChange && props.order) {
      if (props.order.sortBy !== column.orderableKey) {
        props.onOrderChange({
          sortBy: column.orderableKey,
          order: "ASC",
        });
        query.set("order", "ASC");
        if (column.orderableKey) {
          query.set("sortBy", column.orderableKey);
        }
      } else {
        props.onOrderChange({
          sortBy: props.order.order === "DESC" ? undefined : props.order.sortBy,
          order: props.order.order === "ASC" ? "DESC" : "ASC",
        });
        query.set("order", props.order.order === "ASC" ? "DESC" : "ASC");
        if (column.orderableKey && props.order.sortBy) {
          query.set(
            "sortBy",
            props.order.order === "DESC"
              ? column.orderableKey
              : props.order.sortBy
          );
        }
      }
      setQuery(query);
    }
  };

  const theadBg = useColorModeValue("gray.200", "gray.800");
  const oddRowBg = useColorModeValue("gray.100", "gray.900");

  return (
    <Box>
      <Box
        border={"1px"}
        borderColor={oddRowBg}
        borderRadius={4}
        overflow="hidden"
      >
        {/* <pre>{JSON.stringify({
          pageIndex,
          pageSize,
          pageCount,
          canNextPage,
          canPreviousPage
        }, null, 2)}</pre> */}
        <TableChakra {...getTableProps()}>
          <Thead bg={theadBg}>
            {headerGroups.map((_group) => (
              <Tr {..._group.getHeaderGroupProps()}>
                {_group.headers.map((_column) => (
                  <Th
                    {..._column.getHeaderProps()}
                    textAlign={_column.textAlign || "center"}
                    py={4}
                    fontSize={12}
                    fontWeight={"semibold"}
                    color={"gray.500"}
                    fontFamily="inherit"
                    whiteSpace={"nowrap"}
                    cursor={_column.orderableKey ? "pointer" : "default"}
                    onClick={() => handleColumnClick(_column)}
                    className={classNames([
                      _column.orderableKey && "orderable-th",
                      props.order?.sortBy === _column.orderableKey &&
                        "orderable-active",
                    ])}
                  >
                    <Flex justifyContent={"space-between"} alignItems="center">
                      {_column.render("Header")}

                      {_column.orderableKey && (
                        <Button className="button-order" size={"xs"}>
                          <Icon
                            as={
                              props.order?.order === "ASC"
                                ? FiChevronUp
                                : FiChevronDown
                            }
                          />
                        </Button>
                      )}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          {/* Apply the table body props */}
          {props.isLoading ? (
            <Tbody>
              {new Array(6).fill(null).map(() => (
                <Tr>
                  <Td>
                    <Skeleton
                      height={props.withImageSkeleton ? 20 : 6}
                      mx={4}
                    />
                  </Td>
                  <Td colSpan={headerGroups[0].headers.length - 2}>
                    <Skeleton height={6} my={4} />
                  </Td>
                  <Td>
                    <Skeleton height={6} my={4} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          ) : (
            <Tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <Tr
                    {...row.getRowProps()}
                    fontSize={14}
                    backgroundColor={i % 2 ? oddRowBg : "transparent"}
                  >
                    {row.cells.map((cell) => (
                      <Td py={3} {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </Td>
                    ))}
                  </Tr>
                );
              })}

              {rows.length < 1 && (
                <Tr>
                  <Td
                    colSpan={headerGroups[0].headers.length}
                    py={8}
                    textAlign={"center"}
                  >
                    {t("No data")}
                  </Td>
                </Tr>
              )}
            </Tbody>
          )}
        </TableChakra>
      </Box>

      {props.pagination && props.onChange ? (
        <Pagination
          isLoading={props.isLoading}
          pagination={props.pagination}
          onChange={props.onChange}
        />
      ) : null}
    </Box>
  );
};

export default Table;

import { Container, Heading, Text } from "@chakra-ui/react";
import { FC, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { ROLES } from "../def/user.def";

type Props = {
  role: "user" | "admin";
};

const ProtectedPage: FC<Props> = ({ children }) => {
  const { user, isLoading } = useContext(AuthContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (!user && !isLoading) {
      navigate(`/auth/login?from=${pathname}`);
    }
  }, [user, navigate, isLoading, pathname]);

  const flattenRoles = user?.roles.map((_role) => _role.name);
  const allowedRoles: string[] = [ROLES.SUPER_ADMIN, ROLES.MENTOR];

  let isAllowed = false;

  flattenRoles?.forEach((_role) => {
    isAllowed = isAllowed || allowedRoles.includes(_role);
  });

  if (!isAllowed && !isLoading) {
    return (
      <Container marginTop={[8, 12, 14]} centerContent>
        <Heading>{t("Unauthorized")}</Heading>
        <Text marginTop={[12]}>
          {t("You are not permitted to access this page")}
        </Text>
        {/* <pre>{JSON.stringify(user, null, 2)}</pre> */}
      </Container>
    );
  }

  return <>{children}</>;
};

export default ProtectedPage;

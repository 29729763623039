import { Box, Container, Flex, Img } from "@chakra-ui/react";
import { FC } from "react";
import { Outlet } from "react-router-dom";
import GuestPage from "../../middleware/GuestPage";

const AuthLayout: FC = () => {
  const renderImgPatterns = () => (
    <Box width={["100%"]} position={"absolute"} zIndex={1}>
      <Flex width={"100%"} justifyContent={["space-between"]}>
        <Box>
          <Img src="/images/pattern-1.png" />
        </Box>
        <Box>
          <Img src="/images/pattern-2.png" />
        </Box>
      </Flex>
    </Box>
  );

  return (
    <GuestPage>
      <Box position="relative">
        {renderImgPatterns()}

        <Container position={"relative"} zIndex={2}>
          <Outlet />
        </Container>
      </Box>
    </GuestPage>
  );
};

export default AuthLayout;

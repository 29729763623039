import axios, { AxiosInstance, AxiosError } from "axios";

type OptionsType = {
  accessToken?: string;
};

type HookType = (options?: OptionsType) => {
  http: AxiosInstance;
  isAxiosError: (payload: any) => payload is AxiosError;
};

const defaultHttp: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || "http://localhost:3001",
});

console.log("useAxios create http default");

const useAxios: HookType = (options) => {
  const http = !options?.accessToken
    ? defaultHttp
    : axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL || "http://localhost:3001",
        headers: {
          Authorization: `Bearer ${options.accessToken}`,
        },
      });

  return {
    isAxiosError: axios.isAxiosError,
    http,
  };
};

export default useAxios;

import { FC, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { renderSuspense } from "../../../helpers/renderSuspense";

const ContentPayment = lazy(() => import("./ContentPayment"));
const ContentPaymentAdd = lazy(() => import("./add/ContentPaymentAdd"));

export const PaymentRoutes: FC = () => {
  return (
    <Routes>
      <Route path="/" element={renderSuspense(<ContentPayment />)} />
      <Route path="/add" element={renderSuspense(<ContentPaymentAdd />)} />
      <Route
        path="/:paymentId/edit"
        element={renderSuspense(<ContentPaymentAdd />)}
      />
    </Routes>
  );
};

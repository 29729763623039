import { FC, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { renderSuspense } from "../../helpers/renderSuspense";

const Fields = lazy(() => import("./fields/BusinessFields"));
const FieldsAdd = lazy(() => import("./fields/add/BusinessFieldsAdd"));
const Users = lazy(() => import("./users/BusinessUsers"));
const UsersAdd = lazy(() => import("./users/add/BusinessUsersAdd"));
const Sells = lazy(() => import("./sells/BusinessSells"));
const SellsDetail = lazy(() => import("./sells/detail/SellsDetail"));
const SellsAdd = lazy(() => import("./sells/add/BusinessSellsAdd"));
const SellInbox = lazy(() => import("./sellInbox/sellInboxes"));
const SellInboxDetail = lazy(
  () => import("./sellInbox/detail/SellInboxDetail")
);
const Suplies = lazy(() => import("./suply/BusinessSuply"));
const SupliesDetail = lazy(() => import("./suply/detail/SuplyDetail"));
const SupliesAdd = lazy(() => import("./suply/add/BusinessSupplyAdd"));
const Interest = lazy(() => import("./interest/BusinessInterest"));
const InterestDetail = lazy(
  () => import("./interest/detail/BusinessInterestDetail")
);
const InterestAdd = lazy(() => import("./interest/add/BusinessInterestAdd"));

export const BusinessRoutes: FC = () => {
  return (
    <Routes>
      <Route path="/fields" element={renderSuspense(<Fields />)} />
      <Route path="/fields/add" element={renderSuspense(<FieldsAdd />)} />
      <Route
        path="/fields/:businessId/edit"
        element={renderSuspense(<FieldsAdd />)}
      />
      <Route path="/user-business" element={renderSuspense(<Users />)} />
      <Route path="/user-business/add" element={renderSuspense(<UsersAdd />)} />
      <Route
        path="/user-business/:businessId/edit"
        element={renderSuspense(<UsersAdd />)}
      />
      <Route path="/sells" element={renderSuspense(<Sells />)} />
      <Route path="/sells/add" element={renderSuspense(<SellsAdd />)} />
      <Route
        path="/sells/:sellId/edit"
        element={renderSuspense(<SellsAdd />)}
      />
      <Route path="/sells/:sellId" element={renderSuspense(<SellsDetail />)} />
      <Route path="/sellInbox" element={renderSuspense(<SellInbox />)} />
      <Route
        path="/sellInbox/:sellInboxId"
        element={renderSuspense(<SellInboxDetail />)}
      />
      <Route path="/suplies" element={renderSuspense(<Suplies />)} />
      <Route path="/suplies/add" element={renderSuspense(<SupliesAdd />)} />
      <Route
        path="/suplies/:suplyId/edit"
        element={renderSuspense(<SupliesAdd />)}
      />
      <Route
        path="/suplies/:suplyId"
        element={renderSuspense(<SupliesDetail />)}
      />
      <Route path="/interests" element={renderSuspense(<Interest />)} />
      <Route path="/interests/add" element={renderSuspense(<InterestAdd />)} />
      <Route
        path="/interests/:interestId/edit"
        element={renderSuspense(<InterestAdd />)}
      />
      <Route
        path="/interests/:interestId"
        element={renderSuspense(<InterestDetail />)}
      />
    </Routes>
  );
};

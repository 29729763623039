import useAxios from "./useAxios";
import swr, { KeyedMutator } from "swr";
import { AxiosPromise, AxiosResponse } from "axios";
import useGql from "./useGql";
// import { useEffect, useState } from "react";

type OptionsType = {
  accessToken?: string;
  useAxios?: boolean;
};

type HookType = (
  url: string | [string, object] | null,
  options?: OptionsType
) => {
  isLoading: boolean;
  isValidating: boolean;
  isError: boolean;
  mutate: KeyedMutator<any>;
  error?: unknown;
  data?: any;
};

type Fetcher = (url: string | null) => AxiosPromise<AxiosResponse>;

const useSwr: HookType = (url, options) => {
  const { http } = useAxios({
    accessToken: options?.accessToken,
  });
  const { gqlClient } = useGql({ accessToken: options?.accessToken });

  const axiosFetcher: Fetcher = (url) =>
    http.get(url || "").then(({ data }) => data);
  const graphqlFetcher = (url: string, args?: object) => {
    return gqlClient.request(url, args);
  };

  const { data, error, isValidating, mutate } = swr(
    url,
    options?.useAxios ? axiosFetcher : graphqlFetcher
  );

  // const [data, setData] = useState<any[] | undefined>(undefined);

  // useEffect(() => {
  //   if (swrData !== undefined) {
  //     setData(swrData);
  //   }
  // }, [swrData]);

  return {
    isLoading: !!(!data && !error && url),
    isValidating,
    isError: !!error,
    data,
    mutate,
  };
};

export default useSwr;

import { Box, Flex, Text } from "@chakra-ui/layout";
import { Skeleton } from "@chakra-ui/skeleton";
import { format } from "date-fns";
import { FC } from "react";
import Card from "../../../../components/Card/Card";
import Pagination from "../../../../components/Pagination/Pagination";
import { PaginationType } from "../../../../components/Pagination/pagination.type";
import { DeletedUsers } from "../delete-user.type";

interface Props {
  isLoading: boolean;
  items: DeletedUsers[];
  pagination: PaginationType;
  onPagingChange: (pagination: PaginationType) => void;
}

const DeleteUsersContentGrid: FC<Props> = ({
  isLoading,
  items,
  pagination,
  onPagingChange,
}) => {
  return (
    <Box>
      {isLoading
        ? new Array(4).fill(null).map(() => <Skeleton height={42} mb={6} />)
        : items.map((_item) => (
            <Card padding={0} marginBottom={6} overflow={"hidden"}>
              <Flex
                mt={4}
                mx={4}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
              >
                <Box>
                  <Flex>
                    <Box ml={4}>
                      <Text
                        fontSize={"18px"}
                        fontWeight={"semibold"}
                        marginBottom={2}
                      >
                        {_item?.name?.substring(0, 100) || "-"}
                      </Text>
                      <Text fontSize={"12px"} color={"gray.500"}>
                        {_item.basic.createdAt
                          ? format(
                              new Date(_item.basic.createdAt || ""),
                              "d MMMM yyyy"
                            )
                          : "-"}
                      </Text>
                      <Text fontSize={"14px"} marginBottom={1}>
                        {_item.phone ? _item.phone : "-"}
                      </Text>
                    </Box>
                  </Flex>
                </Box>
              </Flex>
            </Card>
          ))}

      <Pagination
        isLoading={isLoading}
        pagination={pagination}
        onChange={onPagingChange}
      />
    </Box>
  );
};

export default DeleteUsersContentGrid;

import { FC, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { renderSuspense } from "../../helpers/renderSuspense";

const Notification = lazy(() => import("./Notification"));

export const NotificationRoutes: FC = () => {
  return (
    <Routes>
      <Route path="/" element={renderSuspense(<Notification />)} />
    </Routes>
  );
};

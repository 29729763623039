import "./Sidebar.css";

import {
  Box,
  Button,
  Center,
  Flex,
  Icon,
  Skeleton,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { FC, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../../../components/Logo/Logo";
import AuthContext from "../../../../context/AuthContext";
import { BlurContext } from "../../../../context/BlurContext";
import { SidebarContext } from "../../../../context/SidebarContext";
import { sidebarNavGroups, SidebarNav } from "./SidebarData";
import { FcPrevious } from "react-icons/fc";
import { BiLogOutCircle } from "react-icons/bi";
import { useRoles } from "../../../../hooks/useRoles";

const Sidebar: FC = () => {
  const { t } = useTranslation();
  const { isBlur, setIsBlur } = useContext(BlurContext);
  const { isExpanded, setIsExpanded } = useContext(SidebarContext);
  const { logout, isLoading: isAuthLoading } = useContext(AuthContext);
  const { pathname } = useLocation();
  const { isForRoles } = useRoles();

  useEffect(() => {
    setIsBlur(isExpanded && window.innerWidth <= 482);
  }, [isExpanded, setIsBlur]);

  const left = useMemo(() => {
    return isExpanded ? 0 : ["-280px", "-265px", "-280px"];
  }, [isExpanded]);

  const isActive = (item: SidebarNav) => {
    if (item.href === "/") {
      return pathname === item.href;
    }

    return pathname.includes(item.href);
  };

  const handleHideSidebarOnMobile = () => {
    if (window.innerWidth <= 482) {
      setIsExpanded(false);
    }
  };

  const bg = useColorModeValue("white", "gray.800");
  const menuColorDefault = useColorModeValue("blackAlpha.600", "gray.400");
  const menuColorActive = useColorModeValue("blackAlpha.700", "gray.300");

  const buttonLogoutBg = useColorModeValue("white", "gray.700");
  const buttonLogoutColor = useColorModeValue("danger.500", "red.300");

  return (
    <>
      {/* overlay on mobile */}
      <Box
        display={[isExpanded ? "block" : "none", "none", "none"]}
        position={"fixed"}
        background={"blackAlpha.300"}
        top={0}
        left={0}
        width={"100%"}
        height={"100%"}
        zIndex={79}
        onClick={() => setIsExpanded(false)}
      />
      {/* end overlay on mobile */}

      <Flex
        position={"fixed"}
        direction="column"
        width={["280px", "265px", "280px"]}
        top={0}
        bottom={0}
        left={left}
        zIndex={100}
        height={"100vh"}
        background={bg}
        // boxShadow={`36.8429px 92.9656px 286px rgba(7, 75, 105, 0.04),
        // 23.8796px 60.2555px 167.495px rgba(7, 75, 105, 0.0303704),
        // 14.1913px 35.809px 91.0963px rgba(7, 75, 105, 0.0242963),
        // 7.36857px 18.5931px 46.475px rgba(7, 75, 105, 0.02),
        // 3.00201px 7.57498px 23.3037px rgba(7, 75, 105, 0.0157037),
        // 0.682275px 1.72159px 11.2546px rgba(7, 75, 105, 0.00962963);`}
        boxShadow={"sm"}
      >
        <Box
          borderBottom={"1px solid"}
          borderColor={"blackAlpha.200"}
          padding={"20px 16px 20px 18px"}
        >
          <Flex
            as={"header"}
            justifyContent={"space-between"}
            alignItems="center"
          >
            <Logo />

            <Center cursor={"pointer"} onClick={() => setIsExpanded(false)}>
              <Icon as={FcPrevious} w={6} h={6} />
            </Center>
          </Flex>
        </Box>

        <Flex
          position={"absolute"}
          as={"aside"}
          direction={"column"}
          filter={isBlur ? ["none", "none", "blur(3px)"] : "none"}
          flexGrow={1}
          width={"100%"}
          top={20}
          bottom={0}
          overflowY={"auto"}
          css={{
            "&::-webkit-scrollbar": {
              width: "4px",
            },
            "&::-webkit-scrollbar-track": {
              background: "#ecf3fe",
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              transition: "ease-in-out .5s all",
              background: "#bed4f9",
            },
            "&:hover::-webkit-scrollbar-thumb": {
              background: "#0956DB50",
              borderRadius: "24px",
            },
          }}
        >
          <Box flexGrow={1}>
            {sidebarNavGroups.map((_group) => (
              <Box
                as={"nav"}
                // borderBottom={'1px solid'}
                // borderColor={'blackAlpha.200'}
                className="sidebar"
              >
                {!!_group.title &&
                  !!_group.navs.length &&
                  isForRoles(_group.forRoles) && (
                    <Text
                      fontSize={12}
                      paddingTop={6}
                      paddingBottom={1}
                      paddingLeft={2}
                      color={"gray.400"}
                      fontWeight={"semibold"}
                      textTransform={"uppercase"}
                    >
                      {_group.title}
                    </Text>
                  )}
                <Flex direction={"column"}>
                  {_group.navs.map((_item, i) =>
                    isForRoles(_item.forRoles) ? (
                      <Link
                        key={i.toString()}
                        to={_item.href}
                        className={isActive(_item) ? "active" : ""}
                        onClick={handleHideSidebarOnMobile}
                      >
                        <Flex
                          alignItems={"center"}
                          paddingX={[4]}
                          paddingY={[2, 3]}
                          gap={3}
                          fontSize={["14px", "16px"]}
                          color={
                            isActive(_item) ? menuColorActive : menuColorDefault
                          }
                          fontWeight={isActive(_item) ? "semibold" : "normal"}
                        >
                          {_item.icon}
                          {t(_item.title)}
                        </Flex>
                      </Link>
                    ) : null
                  )}
                </Flex>
              </Box>
            ))}
          </Box>
          <Box paddingX={4} paddingY={6}>
            <Skeleton isLoaded={!isAuthLoading}>
              <Button
                color={buttonLogoutColor}
                border="1px solid"
                borderColor={{ buttonLogoutColor }}
                borderRadius="0"
                background={buttonLogoutBg}
                width="100%"
                size={"lg"}
                onClick={logout}
                fontSize={14}
              >
                <Flex alignItems={"center"} gap="10px">
                  <Icon as={BiLogOutCircle} color={buttonLogoutColor} />

                  {t("Logout")}
                </Flex>
              </Button>
            </Skeleton>
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default Sidebar;

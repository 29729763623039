import "./Pagination.css";

import Icon from "@chakra-ui/icon";
import { Box, Center, Flex } from "@chakra-ui/layout";
import { Skeleton } from "@chakra-ui/skeleton";
import { FC, ReactNode } from "react";
import { FcNext, FcPrevious } from "react-icons/fc";
import { PaginationType } from "./pagination.type";
import { default as RcPagination } from "rc-pagination";
import { Select } from "@chakra-ui/select";
import { useColorModeValue } from "@chakra-ui/react";

type ItemRender = (
  page: number,
  type: "page" | "prev" | "next" | "jump-prev" | "jump-next",
  element: ReactNode
) => ReactNode;

interface Props {
  isLoading?: boolean;
  pagination: PaginationType;
  onChange: (pagination: PaginationType) => void;
}

const Pagination: FC<Props> = ({ isLoading, pagination, onChange }) => {
  const handlePageChange = (_pagination: PaginationType) => {
    console.log(_pagination);
    onChange(_pagination);
  };

  const paginationDefaultColor = useColorModeValue("gray.500", "gray.300");

  const renderItemPagination: ItemRender = (page, type) => {
    if (type === "page") {
      return (
        <Center
          cursor={"pointer"}
          fontSize={"15px"}
          lineHeight={"28px"}
          color={page === pagination.page ? "white" : paginationDefaultColor}
          backgroundColor={page === pagination.page ? "primary.500" : ""}
          fontWeight={page === pagination.page ? "semibold" : ""}
          borderRadius={"full"}
          width={[9]}
          style={{
            aspectRatio: "1/1",
          }}
        >
          {page}
        </Center>
      );
    }

    if (type === "prev" || type === "next") {
      return (
        <Center
          cursor="pointer"
          width={[8]}
          style={{
            aspectRatio: "1/1",
          }}
        >
          <Icon as={type === "prev" ? FcPrevious : FcNext} w={3} h={3} />
        </Center>
      );
    }

    return <Box>{page}</Box>;
  };

  return (
    <Flex justifyContent={"space-between"} mt={[4]}>
      <Box w={[16]}>
        <Select
          size={"sm"}
          value={pagination.limit}
          onChange={(e) =>
            handlePageChange({ ...pagination, limit: +e.target.value, page: 1 })
          }
        >
          {[1, 5, 10, 15, 30, 50].map((i) => (
            <option key={i} value={i}>
              {i}
            </option>
          ))}
        </Select>
      </Box>
      <Skeleton isLoaded={!isLoading}>
        <RcPagination
          current={pagination.page}
          total={pagination.total}
          pageSize={pagination.limit}
          itemRender={renderItemPagination}
          onChange={(page) => handlePageChange({ ...pagination, page })}
        />
      </Skeleton>
      <Box w={[16]} />
    </Flex>
  );
};

export default Pagination;

import { Icon } from "@chakra-ui/react";
import { RiGroupLine } from "react-icons/ri";
import { HiDocumentDuplicate, HiGlobeAlt } from "react-icons/hi";
import {
  FcDoughnutChart,
  FcTemplate,
  FcDocument,
  FcMoneyTransfer,
  FcFile,
  FcMultipleDevices,
  FcOnlineSupport,
  FcInspection,
} from "react-icons/fc";
import {
  IoCalendarNumberOutline,
  IoNotifications,
  IoSettings,
} from "react-icons/io5";
import {
  FaInbox,
  FaMoneyCheck,
  FaSellcast,
  FaSuperpowers,
  FaTools,
} from "react-icons/fa";
import { ROLES } from "../../../../def/user.def";
import { MdOutlineFindInPage } from "react-icons/md";
import { BiCabinet } from "react-icons/bi";

export type SidebarNav = {
  title: string;
  icon: JSX.Element;
  href: string;
  forRoles?: ROLES[];
};

export type SidebarNavGroup = {
  title?: string;
  navs: SidebarNav[];
  forRoles?: ROLES[];
};

export const sidebarNavGroups: SidebarNavGroup[] = [
  {
    navs: [
      {
        title: "Dashboard",
        icon: <Icon as={FcDoughnutChart} w={5} h={5} />,
        href: "/",
      },
      {
        title: "Users",
        icon: <Icon as={RiGroupLine} color={"red.500"} w={5} h={5} />,
        href: "/users",
      },
    ],
  },
  {
    title: "Consultation",
    navs: [
      {
        title: "Consultations",
        icon: <Icon as={FcOnlineSupport} w={5} h={5} />,
        href: "/consultation/consultations",
        forRoles: [ROLES.MENTOR, ROLES.SUPER_ADMIN],
      },
      {
        title: "Consultation Fields",
        icon: <Icon as={BiCabinet} w={5} h={5} />,
        href: "/consultation/consultationField",
        forRoles: [ROLES.MENTOR, ROLES.SUPER_ADMIN],
      },
    ],
  },
  {
    title: "Content",
    navs: [
      {
        title: "Sliders",
        icon: <Icon as={FcTemplate} color={"red.500"} w={5} h={5} />,
        href: "/content/sliders",
      },
      {
        title: "Page",
        icon: <Icon as={MdOutlineFindInPage} color={"red.500"} w={5} h={5} />,
        href: "/content/pages",
      },
    ],
  },
  {
    title: "Donations",
    forRoles: [ROLES.SUPER_ADMIN],
    navs: [
      {
        title: "Donasi",
        icon: <Icon as={FaMoneyCheck} color={"red.500"} w={5} h={5} />,
        href: "/donation",
      },
      {
        title: "Payment Method",
        icon: <Icon as={FcMoneyTransfer} color={"red.500"} w={5} h={5} />,
        href: "/subscription/payment-methods",
      },
    ],
  },
  {
    title: "Business Inspiration",
    navs: [
      {
        title: "Category",
        icon: (
          <Icon as={HiDocumentDuplicate} color={"orange.500"} w={5} h={5} />
        ),
        href: "/blog/categories",
      },
      {
        title: "Post",
        icon: <Icon as={FcDocument} color={"red.500"} w={5} h={5} />,
        href: "/blog/posts",
      },
    ],
  },
  {
    title: "Business",
    navs: [
      {
        title: "Business Fields",
        icon: <Icon as={FcFile} color={"orange.500"} w={5} h={5} />,
        href: "/business/fields",
      },
      {
        title: "User Business",
        icon: <Icon as={FcMultipleDevices} color={"red.500"} w={5} h={5} />,
        href: "/business/user-business",
      },
      {
        title: "Business Sell",
        icon: <Icon as={FaSellcast} color={"red.500"} w={5} h={5} />,
        href: "/business/sells",
      },
      {
        title: "Business Sell Inbox",
        icon: <Icon as={FaInbox} color={"red.500"} w={5} h={5} />,
        href: "/business/sellInbox",
      },
      {
        title: "Business Supply",
        icon: <Icon as={FaSuperpowers} color={"red.500"} w={5} h={5} />,
        href: "/business/suplies",
      },
      {
        title: "Business Interest",
        icon: <Icon as={FcInspection} color={"red.500"} w={5} h={5} />,
        href: "/business/interests",
      },
    ],
  },
  {
    title: "IIBF Tools",
    navs: [
      {
        title: "IIBF Tools",
        icon: <Icon as={FaTools} color={"green.500"} w={5} h={5} />,
        href: "/iibf-tools",
      },
    ],
  },
  {
    title: "Event",
    navs: [
      {
        title: "Event",
        icon: (
          <Icon as={IoCalendarNumberOutline} color={"green.500"} w={5} h={5} />
        ),
        href: "/events",
      },
    ],
  },
  {
    title: "Leader Forum",
    navs: [
      {
        title: "Leader Forum",
        icon: <Icon as={HiGlobeAlt} color={"green.500"} w={5} h={5} />,
        href: "/leader-forum",
      },
    ],
  },
  {
    title: "Notification",
    navs: [
      {
        title: "Send Notification",
        icon: <Icon as={IoNotifications} color={"orange.400"} w={5} h={5} />,
        href: "/notifications",
      },
    ],
  },
  {
    title: "Settings",
    navs: [
      {
        title: "Setting",
        icon: <Icon as={IoSettings} color={"blue.500"} w={5} h={5} />,
        href: "/settings",
      },
    ],
  },
];

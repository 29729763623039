import { Center, CircularProgress } from "@chakra-ui/react";
import { ReactNode, Suspense, useEffect, useMemo, useState } from "react";

type HookType = (options: {
  mobile?: JSX.Element | ReactNode | null;
  desktop?: JSX.Element | ReactNode | null;
}) => JSX.Element | ReactNode;

const useComponentByScreen: HookType = ({ mobile, desktop }) => {
  const [width, setWidth] = useState(window.innerWidth);

  const onResize = () => {
    console.log(window.innerWidth, "isMobile", window.innerWidth <= 482);
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);

    return () => window.removeEventListener("resize", onResize);
  }, []);

  const renderFallback = () => (
    <Center py={[4, 20]}>
      <CircularProgress isIndeterminate color="primary.500" />
    </Center>
  );

  const el = useMemo(() => {
    if (width >= 768) {
      return desktop;
    }

    return mobile;
  }, [desktop, mobile, width]);

  if (!el) {
    return null;
  }

  return <Suspense fallback={renderFallback()}>{el}</Suspense>;
};

export default useComponentByScreen;

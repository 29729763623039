import { FC, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { renderSuspense } from "../../helpers/renderSuspense";

const Settings = lazy(() => import("./Settings"));

export const SettingsRoutes: FC = () => {
  return (
    <Routes>
      <Route path="/" element={renderSuspense(<Settings />)} />
    </Routes>
  );
};

import { FC, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { renderSuspense } from "../../helpers/renderSuspense";

const Consultation = lazy(() => import("./consultations/Consultation"));
const ConsultationDetail = lazy(
  () => import("./consultations/detail/ConsultationDetail")
);
const ConsultationField = lazy(
  () => import("./consultationField/ConsultationField")
);
const ConsultationFieldDetail = lazy(
  () => import("./consultations/detail/ConsultationDetail")
);
const ConsultationFieldAdd = lazy(
  () => import("./consultationField/add/ConsultationFieldAdd")
);

export const ConsultationRoutes: FC = () => {
  return (
    <Routes>
      <Route path="/consultations" element={renderSuspense(<Consultation />)} />
      <Route
        path="/consultations/:consultationId"
        element={renderSuspense(<ConsultationDetail />)}
      />
      <Route
        path="/consultationField"
        element={renderSuspense(<ConsultationField />)}
      />
      <Route
        path="/consultationField/:consultationId"
        element={renderSuspense(<ConsultationFieldDetail />)}
      />
      <Route
        path="/consultationField/add"
        element={renderSuspense(<ConsultationFieldAdd />)}
      />
      <Route
        path="/consultationField/:consultationFieldId/edit"
        element={renderSuspense(<ConsultationFieldAdd />)}
      />
    </Routes>
  );
};

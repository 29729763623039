import { Button } from "@chakra-ui/button";
import { Box, Flex } from "@chakra-ui/layout";
import { Icon } from "@chakra-ui/react";
import { gql } from "graphql-request";
import { useDebouncedCallback } from "use-debounce";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiSearch } from "react-icons/fi";
import { NavLink, useSearchParams } from "react-router-dom";
import Card from "../../../components/Card/Card";
import Input from "../../../components/Input/Input";
import AuthContext from "../../../context/AuthContext";
import usePagination from "../../../hooks/usePagination";
import useSwr from "../../../hooks/useSwr";
import AdminLayoutContent from "../../../layouts/Admin/components/Content/AdminLayoutContent";
import useComponentByScreen from "../../../hooks/useComponentByScreen";
import { DeletedUsers } from "./delete-user.type";
import ContentDeleteUsersTable from "./components/ContentDeleteUsersTable";
import DeleteUsersContentGrid from "./components/DeleteUsersContentGrid";

const DeletedUser: FC = () => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthContext);
  const [query, setQuery] = useSearchParams();
  const [inputKeyword, setInputKeyword] = useState<string>(
    query.get("keyword") || ""
  );
  const { pagination, onServerPaginationChange, reflectPaginationToUrl } =
    usePagination();

  const gqlQuery = gql`
    query getDeletedUsers($keyword: String, $page: Int, $limit: Int) {
      deletedUsers(keyword: $keyword, page: $page, limit: $limit) {
        items {
          name
          phone
          basic {
            createdAt
          }
        }
      }
    }
  `;

  const swrKeys = useMemo<[string, object]>(
    () => [
      gqlQuery,
      {
        keyword: inputKeyword,
        page: pagination.page,
        limit: pagination.limit,
      },
    ],
    [gqlQuery, inputKeyword, pagination.limit, pagination.page]
  );

  const { data, isLoading } = useSwr(swrKeys, {
    accessToken,
  });

  const items = useMemo<DeletedUsers[]>(
    () => data?.deletedUsers?.items || [],
    [data]
  );
  useEffect(() => {
    onServerPaginationChange(data?.deletedUsers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, items]);

  const debouncedKeyword = useDebouncedCallback((value) => {
    query.delete("keyword");

    if (value) {
      query.set("keyword", value);
    }

    query.set("page", "1");
    setQuery(query);
  }, 800);

  const content = useComponentByScreen({
    desktop: (
      <ContentDeleteUsersTable
        items={items}
        isLoading={isLoading}
        pagination={pagination}
        onPagingChange={reflectPaginationToUrl}
      />
    ),
    mobile: (
      <DeleteUsersContentGrid
        items={items}
        isLoading={isLoading}
        pagination={pagination}
        onPagingChange={reflectPaginationToUrl}
      />
    ),
  });

  const renderFooter = () => {
    return (
      <Flex justifyContent={"flex-end"} gap={4}>
        <Button as={NavLink} to={`/users`}>
          {t("Back")}
        </Button>
      </Flex>
    );
  };

  return (
    <AdminLayoutContent
      title={t("Deleted Users")}
      subtitle={t("List of request delete users")}
      accessoryBottom={renderFooter()}
    >
      <Card borderBottomRadius={[6, 0]}>
        <Flex gap={4} justifyContent={"space-between"}>
          <Flex alignItems={"center"} gap={2} flexGrow={[1, "unset"]}>
            <Input
              accessoryLeft={<Icon as={FiSearch} />}
              placeholder={t("Search..")}
              value={inputKeyword}
              onChange={(e) => {
                setInputKeyword(e.target.value);
                debouncedKeyword(e.target.value);
              }}
            />
          </Flex>
        </Flex>
        <Box mt={[4, 0]}>{content}</Box>
      </Card>
    </AdminLayoutContent>
  );
};

export default DeletedUser;

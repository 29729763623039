import { FC, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { renderSuspense } from "../../helpers/renderSuspense";

const Events = lazy(() => import("./Events"));
const EventsAdd = lazy(() => import("./add/EventAdd"));
const EventsDetail = lazy(() => import("./detail/EventDetail"));

export const EventRoutes: FC = () => {
  return (
    <Routes>
      <Route path="/" element={renderSuspense(<Events />)} />
      <Route path="/add" element={renderSuspense(<EventsAdd />)} />
      <Route path="/:eventId/edit" element={renderSuspense(<EventsAdd />)} />
      <Route path="/:eventId" element={renderSuspense(<EventsDetail />)} />
    </Routes>
  );
};

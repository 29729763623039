import { extendTheme, ThemeConfig } from "@chakra-ui/react";
import {
  createBreakpoints,
  mode,
  StyleFunctionProps,
} from "@chakra-ui/theme-tools";
import { buttonTheme } from "./buttonThemes";
import { switchTheme } from "./switchThemes";

const breakpoints = createBreakpoints({
  sm: "30em",
  md: "58em",
  lg: "62em",
  xl: "80em",
  "2xl": "96em",
});

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: true,
};
export const theme = extendTheme({
  fonts: {
    heading: "'Patua One', cursive",
    body: "'Inter', sans-serif",
  },
  components: { Button: buttonTheme, Switch: switchTheme },
  breakpoints,
  colors: {
    primary: {
      500: "#3366FF",
    },
    secondary: {
      500: "#30ACFF",
    },
    secondaryText2: "#827D7D",
    textPrimary: "#0D2C23",
    textSecond: "rgba(13, 44, 35, 0.5)",
    text4: "#B3BEC5",
    gold: "#ffbe2e",
    danger: {
      50: "#FFF1EC",
      400: "#ED535A",
      500: "#E21F39",
    },
    textColorMain: "#01263F",
    success: "#54BF5E",
    warning: "#FE9534",
    dark: {
      500: "#1e293b", //for card background desktop
    },
  },
  styles: {
    global: (props: StyleFunctionProps) => ({
      "html, body": {
        // background:
        // "linear-gradient(0deg, rgba(236, 249, 246, 0.2), rgba(236, 249, 246, 0.2)), linear-gradient(0deg, #FFFFFF, #FFFFFF), #FFFFFF;",
        background: mode("gray.50", "gray.800")(props),
      },
      formLabel: {
        fontSize: "12px",
      },
      a: {
        color: "primary.500",
      },
    }),
  },
  config,
});
